<template>
  <div class="pt-2 pr-4 pb-2 pl-4 purchase-ht1">
    <form
      class="row purchase-ht4 no-print"
      enctype="multipart/form-data"
      @submit.prevent="submit"
    >
      <div class="col-md-12 pb-1">
        <div class="row pb-0">
          <div class="col-md-10 pl-0">
            <h4 class="content-title content-title-xs">New Opening Stock</h4>
          </div>
          <div class="col-md-2 float-right text-right pr-0 pl-5">
            <select
              v-model="formData.branch"
              class="form-control right-select-drop"
              :disabled="branchDisabled"
              @change="getDataByBranch"
            >
              <option value>Select Branch</option>
              <option
                v-for="(branch, index) in dataSets.branches"
                :key="index"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-3 bg-theam-secondary mx-0 p-2">
        <div
          :class="
            dataSets.purchase_setting.shipping_details == 1
              ? 'purchase-supplier-ht'
              : 'purchase-supplier-ht-change'
          "
        >
          <div>
            <span class="title-supplier">Supplier</span>
            <button
              class="float-right btn btn-primary btn-xs"
              type="button"
              v-if="checkSinglePermission('create_supplier')"
              @click="
                $store.dispatch('modalWithMode', {
                  id: 'newSupplier',
                  mode: 'create',
                })
              "
            >
              New Supplier
            </button>
          </div>
          <div class="input-group py-2">
            <button
              id="supplier_name"
              class="btn-primary input-group-text"
              type="button"
            >
              <i class="fa fa-user"></i>
            </button>
            <input
              type="text"
              class="form-control"
              placeholder="Supplier Name"
              v-model="formData.supplierName"
              data-toggle="modal"
              data-target="#supplierList"
              @click="focusSearch('supplierSearch')"
              readonly
            />
          </div>
          <div class="row">
            <div class="col-md-5 mr-0 pr-0">
              <label class="page-text" for="date">Purchase Date *</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                v-model.trim="formData.date"
                class="form-control"
                required
                type="date"
              />
            </div>
          </div>
          <div class="row py-2">
            <div class="col-md-5 mr-0 pr-0">
              <label class="page-text" for="purchase_id">Purchase ID</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                v-model="purchaseIdWithPrefix"
                class="form-control"
                type="text"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-5 mr-0 pr-0">
              <label class="page-text" for="chalan_no">Chalan No.</label>
            </div>
            <div class="col-md-7 ml-0 pl-0">
              <input
                id="chalan_no"
                v-model.trim="formData.chalan_no"
                class="form-control"
                name="chalan_no"
                placeholder="Supplier Bill No."
                type="text"
              />
            </div>
          </div>
          <div class="input-group py-2 zi-0">
            <input
              id="file"
              name="file"
              style="display: none"
              type="file"
              @change="base64Image"
            />
            <input
              class="form-control"
              placeholder="Upload PDF / Image"
              readonly
              style="background: #f4f7fa"
              v-model="formData.image"
              type="text"
            />
            <label class="btn-primary input-group-text cursor-p" for="file"
              >Browse</label
            >
          </div>
        </div>
        <!-- <div class>
          <div class="row" v-if="dataSets.purchase_setting">
            <div class="col-md-7 mr-0 pr-0">
              <button
                class="btn btn-xs btn-primary"
                type="button"
                @click="$store.dispatch('modalOpen', 'shippingDetils')"
                v-if="dataSets.purchase_setting.shipping_details==1"
              >Shipping Detail</button>
            </div>
            <div class="col-md-5 ml-0 pt-0 d-flex justify-content-end">
              <div class="from-group-check" v-if="dataSets.purchase_setting.export==1">
                <input
                  id="expense_import"
                  v-model="formData.shipping_type"
                  class="group-check-box purchase-import-check"
                  name="expense_type"
                  type="checkbox"
                  value="0"
                />
                <label class="page-text-import pd-t-10 ml-2 mb-0" for="expense_import">Import</label>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="col-md-9 mx-0 pl-2 pr-0 purchase-ht2">
        <div class="bg-theam-secondary p-2">
          <div class="row pt-0">
            <div class="col-md-6 mr-0 pr-0">
              <div class="page-text">Item</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <button
                    id="product"
                    class="btn-primary input-group-text"
                    type="button"
                  >
                    <i class="fas fa-briefcase"></i>
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Item"
                  v-model="items.name"
                  data-toggle="modal"
                  data-target="#purchaseItemList"
                  @click="openPurchaseitemComponent()"
                  readonly
                />
                <button
                  class="float-left btn btn-primary btn-xs ml-2 pr-0 mt-0 mr-2"
                  type="button"
                  v-if="checkSinglePermission('create_item')"
                  @click="
                    $store.dispatch('modalWithMode', {
                      id: 'newItem',
                      mode: 'create',
                    })
                  "
                >
                  New Item
                </button>
              </div>
            </div>
            <!-- <div class="col-md-1 pl-2 m-0 order-add-item-btn-pd">
              <button
                  class="float-left btn btn-primary btn-xs px-0"
                  type="button"
                  @click="
                  $store.dispatch('modalWithMode', {
                    id: 'newItem',
                    mode: 'create',
                  })
                "
              >
                New
              </button>
            </div>-->
            <div
              class="col-md-4 mr-0 ml-0 pl-1"
              v-if="dataSets.item_setting.barcode == 1"
            >
              <div class="page-text">Barcode</div>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span id="barcode" class="input-group-text">
                    <i class="fas fa-barcode"></i>
                  </span>
                </div>
                <input
                  v-model.trim="items.barcode"
                  class="form-control"
                  placeholder="Barcode"
                  type="text"
                />
              </div>
            </div>
            <div class="col-md-2 pl-0" v-if="dataSets.item_setting.expiry == 1">
              <div class="page-text">Expiry Date</div>
              <div class="input-group">
                <input
                  v-model="items.expiry_date"
                  class="form-control"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2 mr-0 pr-0 pt-2 zi-0">
              <div class="page-text">Quantity *</div>
              <div class="input-group">
                <input
                  v-model.number="items.quantity"
                  class="form-control"
                  min="1"
                  placeholder="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
                <div class="input-group-prepend">
                  <span
                    id="quantity"
                    class="input-group-text"
                    style="background: #8db8ee; color: black"
                    >{{ items.unit_type }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-2 mr-0 pr-0 pt-2 pr-2">
              <div class="page-text">Purchase Rate</div>
              <div class="input-group">
                <input
                  v-model="items.purchase_rate"
                  class="form-control"
                  placeholder="Amount"
                  step="any"
                  min="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
              </div>
            </div>
            <div class="col-md-2 pt-2 pr-0 pl-1">
              <div class="page-text">Purchase Discount</div>
              <div class="input-group">
                <input
                  class="form-control"
                  v-model="items.purchase_discount_percentage"
                  placeholder="0.00"
                  step="any"
                  min="0"
                  type="number"
                  @keyup="calculatePurchaseDiscount()"
                  @change="calculatePurchaseDiscount()"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text px-1" style="min-width: 0px"
                    >%</span
                  >
                </div>
                <input
                  class="form-control wd-20pimp"
                  v-model="items.purchase_discount_amount"
                  placeholder="0.00"
                  step="any"
                  type="number"
                  min="0"
                  @keyup="changePurchaseRate()"
                  @change="changePurchaseRate()"
                />
              </div>
            </div>
            <div class="col-md-2 mr-0 pr-0 pt-2">
              <div class="page-text">A. Purchase Rate *</div>
              <div class="input-group">
                <input
                  class="form-control"
                  v-model="items.actual_purchase_rate"
                  placeholder="Amount"
                  step="any"
                  type="number"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-2 mr-0 pt-2">
              <div class="page-text">Sales Rate</div>
              <div class="input-group">
                <input
                  v-model="items.sales_rate"
                  class="form-control"
                  placeholder="Amount"
                  min="0"
                  step="any"
                  type="number"
                  @keyup="discountAmount()"
                  @change="discountAmount()"
                />
              </div>
            </div>
            <div class="col-md-2 pt-2 pl-0">
              <div class="page-text">Sales Discount</div>
              <div class="input-group">
                <input
                  v-model="items.sales_discount_percentage"
                  class="form-control"
                  placeholder="0.00"
                  min="0"
                  step="any"
                  @keyup="discountAmount()"
                  @change="discountAmount()"
                  type="number"
                />
                <div class="input-group-prepend">
                  <span class="input-group-text px-1" style="min-width: 0px"
                    >%</span
                  >
                </div>
                <input
                  v-model="items.sales_discount_amount"
                  @keyup="discountPercentage()"
                  @change="discountPercentage()"
                  class="form-control wd-20pimp"
                  placeholder="0.00"
                  min="0"
                  step="any"
                  type="number"
                  @keydown.tab.exact="
                    options.edit == true ? updateItem() : addItem()
                  "
                />
              </div>
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-md-1 mr-0 pr-0">
              <label class="page-text" for="date">Total</label>
            </div>
            <div class="col-md-2 ml-0 pl-0">
              <input
                v-model="items.total"
                class="form-control"
                disabled
                type="number"
              />
            </div>
            <div class="col-md-9 d-flex justify-content-end">
              <div v-if="options.edit">
                <div
                  class="btn btn-success btn-xs"
                  type="button"
                  @click="updateItem"
                >
                  Update Item
                </div>
                <div
                  class="btn btn-danger btn-xs ml-2"
                  type="button"
                  @click="closeUpdate()"
                >
                  Close
                </div>
              </div>
              <div
                v-else
                class="btn btn-success btn-xs"
                type="button"
                @click="addItem"
              >
                Add Item
              </div>
            </div>
          </div>
        </div>
        <div class="bg-theam-secondary mt-2 purchase-ht3 purchase-table">
          <table class="table table1">
            <thead>
              <tr>
                <th class="wd-5p table-start-item" scope>S.N.</th>
                <th class="wd-35p" scope>Particular</th>
                <th class="wd-10p" scope>Qty</th>
                <th class="wd-5p" scope>Rate</th>
                <th scope class="wd-10p">Disc.</th>
                <th class="wd-10p" scope>Sales Rate</th>
                <th scope class="wd-10p">Sales Disc.</th>
                <th class="wd-10p" scope>Total</th>
                <th class="wd-10p text-right table-end-item" scope>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in formData.items" :key="index">
                <th class="table-start-item" scope="row">{{ ++index }}</th>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }} {{ item.unit_type }}</td>
                <td>{{ item.purchase_rate }}</td>
                <td>{{ item.purchase_discount_amount }}</td>
                <td>{{ item.sales_rate }}</td>
                <td>{{ item.sales_discount_amount }}</td>
                <td>
                  {{ (item.quantity * item.actual_purchase_rate).toFixed(2) }}
                </td>
                <td class="text-right table-end-item">
                  <a class="mr-2" href="javascript:" @click="editItem(index)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a class="mr-0" href="javascript:" @click="removeItem(index)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-12 bg-theam-secondary p-2 mt-2">
        <div class="row">
          <div class="col-md-3 pr-0">
            <div class="page-text">Note</div>
            <textarea
              v-model.trim="formData.note"
              class="form-control page-note"
              name="note"
            ></textarea>
          </div>
          <div class="col-md-7">
            <div class="row">
              <div class="col-md-3 mr-0 pr-0">
                <div class="page-text">Grand Total</div>
                <div class="input-group">
                  <input
                    v-model="formData.total"
                    class="form-control"
                    disabled
                    placeholder="0.00"
                    step="any"
                    type="number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row justify-content-end mr-0">
              <button
                class="col-md-7 btn btn-success btn-sm btn-min-wd"
                type="submit"
                @click.prevent="submitData()"
                :disabled="submitting"
              >
                {{ submitting ? "Submitting...." : "Submit" }}
              </button>
              <button
                class="col-md-7 btn btn-warning btn-sm btn-min-wd my-2"
                type="button"
                @click="reset"
              >
                Reset
              </button>
              <router-link
                v-if="checkSinglePermission('view_purchase')"
                class="col-md-7 btn btn-danger btn-sm btn-min-wd"
                tag="button"
                :to="{ name: 'purchase-listing' }"
                >Cancel</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="col-md-12">
      <div
        class="modal fade"
        id="supplierList"
        role="dialog"
        aria-hidden="true"
      >
        <div
          class="modal-dialog listing-popup-place modal_ac_head"
          role="document"
        >
          <div class="modal-content search-table-container">
            <!-- search field -->
            <div class="has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="supplierSearched"
                @input="searchSupplier"
                ref="supplierSearch"
              />
            </div>
            <table id="tableData1" class="table table1">
              <thead>
                <tr>
                  <th class="wd-5p table-start-item" scope>S.N.</th>
                  <th class="wd-15p" scope>Supplier Id</th>
                  <th class="wd-30p" scope>Supplier Name</th>
                  <th class="wd-30p" scope>Address</th>
                  <th class="wd-20p" scope>Phone No</th>
                </tr>
              </thead>
              <tbody v-if="!supplierLoading && dataSets.suppliers.length > 0">
                <tr
                  v-for="(supplier, index) in this.dataSets.suppliers"
                  :key="index"
                  @click="selectSupplier(supplier.id)"
                  data-dismiss="modal"
                >
                  <th class="table-start-item" scope="row">{{ ++index }}</th>
                  <td>{{ supplier.id }}</td>
                  <td>{{ supplier.name }}</td>
                  <td>{{ supplier.address }}</td>
                  <td>{{ supplier.phone }}</td>
                </tr>
              </tbody>
              <tbody
                v-else-if="!supplierLoading && dataSets.suppliers.length == 0"
              >
                <tr>
                  <td colspan="11" style="text-align: center">
                    No records found.
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="supplierLoading">
                <tr>
                  <td colspan="11" style="text-align: center">Loading.....</td>
                </tr>
              </tbody>
              <tbody v-else-if="errorLoading">
                Error Fetching data from the server.
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <NewSupplier />
    <NewItem />
    <PurchaseItemComponent
      @clicked="componentItemClicked"
      ref="purchaseItemComponent"
    ></PurchaseItemComponent>
  </div>
</template>
<script>
/* eslint-disable */
import NewSupplier from "../Supplier/NewSupplier";
import NewItem from "../Item/NewItem";
import PurchaseItemComponent from "../Purchase/Components/PurchaseItemComponent";
import { mapGetters } from "vuex";
import Services from "./Services/Service";

export default {
  components: {
    NewSupplier,
    NewItem,
    PurchaseItemComponent,
  },
  data() {
    return {
      options: {
        edit: false,
        index: null,
      },
      dataSets: {
        suppliers: [],
        items: [],
        paymentMethods: [],
        banks: [],
        branches: [],
        purchase_setting: [],
        item_setting: [],
      },
      submitting: false,
      branchDisabled: false,
      supplierSearched: "",
      supplierLoading: false,
      itemSearched: "",
      purchaseIdWithPrefix: 0,
      purchaseId: 0,
      errorLoading: false,
      formData: {
        bill_no: "",
        prefix: "",
        paymentTitle: "",
        reactiveExtraFields: [],
        branch: "",
        supplier_id: null,
        supplierName: "",
        date: null,
        chalan_no: null,
        file: null,
        shipping_type: 0,
        driver: "",
        vehicle: "",
        contact: "",
        payment_method: "",
        extra_fields: [],
        bank: null,
        txn_id: null,
        image: "",
        purchase_amount: null,
        total: null,
        note: null,
        items: [],
      },
      items: {
        name: null,
        unit_type: null,
        vat_applicable: null,
        quantity: 0,
        purchase_rate: null,
        actual_purchase_rate: 0,
        sales_rate: null,
        sales_discount_percentage: null,
        sales_discount_amount: null,
        purchase_discount_percentage: null,
        purchase_discount_amount: null,
        total: 0,
        barcode: null,
        expiry_date: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "today",
      "dataId",
      "dataLists1",
      "dataLists8",
      "eventMessage",
      "modalId",
      "today",
      "authData",
    ]),
    ...mapGetters("openingStock", ["branchId", "slipSettings"]),
    ...mapGetters("suppliers", ["supplierDatas"]),
    ...mapGetters("items", ["itemLists"]),
  },
  mounted() {
    this.formData.date = this.today;
    this.supplierLoading = true;
    this.itemSearchLoading = true;
    this.$store.commit("purchase/setItemSearchLoading", true);
    this.getData();
    this.calculateAmount();
    //coz it was taking extra fields from other page component
    //when switching the page
  },
  methods: {
    searchSupplier: _.debounce(function () {
      let keyword = this.supplierSearched;
      // keyword = keyword ? keyword : '';
      this.supplierLoading = true;
      this.dataSets.suppliers = [];
      Services.getSuppliersBySearchKeyword(keyword)
        .then((response) => {
          let value = response.data.data.data;
          this.dataSets.suppliers = value;
        })
        .catch((error) => {
          console.log(error);
          this.errorLoading = true;
        })
        .finally(() => {
          this.supplierLoading = false;
        });
    }, 600),

    // select the branch if the page relods with added Data
    checkIfBranchExist(branchId) {
      if (this.formData.items.length > 0) {
        if (this.formData.items[0].branch == branchId) {
          this.formData.branch = branchId;
          this.branchDisabled = true;
          return true;
        }
      }
      return false;
    },
    openPurchaseitemComponent() {
      if (this.formData.branch) {
        this.$store.dispatch("modalWithMode", {
          id: "purchaseItemList",
          mode: "create",
        });

        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs.purchaseItemComponent.$refs.itemSearch.focus();
          });
        }, 600);
      } else {
        this.setNotification("Please select branch first.");
      }
    },
    // receiving data from child component i.e SalesItemComponent
    componentItemClicked(details) {
      // this.items = [];
      this.items.item_id = details.id;
      this.items.name = details.name;
      this.items.unit_type = details.unit_type.title;
      this.items.vat_applicable = details.vat_applicable;
      this.items.quantity = 1;
      this.items.barcode = details.barcode;
      this.formData.date = this.today;
    },
    // focuses the input field when the item/customer modal is opened
    focusSearch(searchFieldId) {
      setTimeout(() => {
        this.$refs[searchFieldId].focus();
      }, 500);
    },
    clearItem() {
      this.items.vat_applicable = null;
      this.items.quantity = 0;
      this.items.name = null;
      this.items.purchase_rate = null;
      this.items.sales_rate = null;
      this.items.sales_discount_percentage = null;
      this.items.sales_discount_amount = null;
      this.items.total = null;
      this.items.barcode = null;
      this.items.expiry_date = null;
      this.items.purchase_discount_percentage = null;
      this.items.purchase_discount_amount = null;
      this.items.actual_purchase_rate = 0;
      this.items.unit_type = null;
    },
    base64Image(e) {
      this.formData.image = e.target.files[0]["name"];
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        this.formData.file = e.target.result;
      };
    },
    addItem() {
      if (this.addItemValidation()) {
        if (!this.items.purchase_rate) this.items.purchase_rate = 0;
        if (!this.items.purchase_discount_percentage)
          this.items.purchase_discount_percentage = 0;
        if (!this.items.purchase_discount_amount)
          this.items.purchase_discount_amount = 0;
        if (!this.items.sales_rate) this.items.sales_rate = 0;
        if (!this.items.sales_discount_percentage)
          this.items.sales_discount_percentage = 0;
        if (!this.items.sales_discount_amount)
          this.items.sales_discount_amount = 0;
        if (!this.items.total) this.items.total = 0;
        // this.items.purchase_ /rate < 0 ? this.items.purchase_rate == 0 : "";
        this.formData.items.push({ ...this.items });
        this.branchDisabled = true;
        this.itemExpired = false;
        this.addFormDataToLocalStorage(this.formData);
        this.clearItem();
      }
      this.calculateAmount();
    },
    addItemValidation() {
      if (this.items.quantity <= 0) {
        this.setNotification("Qty should be greater than 0.");
        return false;
      }
      if (this.items.purchase_rate < 0) {
        this.setNotification("Purchase rate cannot be less than 0.");
        return false;
      }
      if (this.items.sales_rate < 0) {
        this.setNotification("Sales rate cannot be less than 0.");
        return false;
      }

      if (
        !this.items.item_id &&
        !this.items.quantity > 0 &&
        !this.items.purchase_rate > 0 &&
        !this.items.sales_rate > 0 &&
        !this.items.total > 0
      ) {
        this.setNotification("Please fill the required fields.");
        return false;
      }
      if (this.formData.items.length > 0) {
        let id = this.items.item_id;
        let status = this.formData.items.find(function (data) {
          return data.item_id == id;
        });
        if (status != undefined) {
          let err = `${this.items.name} already in purchased list.`;
          this.setNotification(err);
          this.clearItem();
          return false;
        }
      }
      return true;
    },
    // Notificaion
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
    discountAmount() {
      if (
        isNaN(this.items.sales_discount_percentage) ||
        this.items.sales_discount_percentage == 0
      ) {
        this.items.sales_discount_amount = 0;
      }
      if (
        this.items.sales_discount_percentage > 0 &&
        this.items.sales_rate > 0
      ) {
        this.items.sales_discount_amount = this.parseDigit(
          (parseFloat(this.items.sales_rate) *
            parseFloat(this.items.sales_discount_percentage)) /
            100
        );
      }
    },
    discountPercentage() {
      if (
        isNaN(this.items.sales_discount_amount) ||
        this.items.sales_discount_amount == 0
      ) {
        this.items.sales_discount_percentage = 0;
      }
      if (this.items.sales_rate && this.items.sales_discount_amount > 0) {
        this.items.sales_discount_percentage = this.parseDigit(
          (parseFloat(this.items.sales_discount_amount) /
            parseFloat(this.items.sales_rate)) *
            100
        );
      }
    },
    removeItem(index) {
      this.$swal({
        title: "Do you really want to remove this item ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.items.splice(index - 1, 1);
          if (this.formData.items.length == 0) {
            this.branchDisabled = false;
          }
          this.calculateTotal();
          this.addFormDataToLocalStorage(this.formData);
        }
      });
    },
    editItem(index) {
      this.items = { ...this.formData.items[index - 1] };
      this.options.edit = true;
      this.options.index = [index - 1];
    },
    updateItem() {
      if (
        this.items.item_id &&
        this.items.quantity > 0 &&
        this.items.purchase_rate > 0 &&
        this.items.sales_rate > 0 &&
        this.items.total > 0
      ) {
        let dataAdded = false;
        let currentAddedItems = this.formData.items;
        currentAddedItems.forEach((el) => {
          if (el.item_id == this.items.item_id) {
            dataAdded = true;
            if (this.formData.items.length < this.options.index) {
              this.formData.items.push({ ...this.items });
            } else {
              this.formData.items[this.options.index] = { ...this.items };
            }
          }
        });
        if (dataAdded == false) {
          this.formData.items.push({ ...this.items });
        }
        this.options.edit = false;

        this.calculateAmount();
        this.clearItem();
      } else {
        this.setNotification("Please fill the required fields.");
      }
    },
    closeUpdate() {
      this.options.edit = false;
      this.items = [];
      this.itemExpired = false;
    },
    calculate() {
      this.items.total = this.parseDigit(
        this.items.quantity * this.items.actual_purchase_rate
      );
    },

    calculatePurchaseDiscount() {
      let rate = this.items.purchase_rate;
      let itemQuantity = this.items.quantity;
      let p_discountPerc = this.items.purchase_discount_percentage;
      if (rate > 0 && p_discountPerc > 0) {
        let discAmount = parseFloat(
          (p_discountPerc * (itemQuantity * rate)) / 100
        );
        this.items.purchase_discount_amount = this.parseDigit(discAmount);
      } else if (rate > 0 && p_discountPerc == 0) {
        this.items.purchase_discount_amount = 0;
      }
      this.items.actual_purchase_rate = this.parseDigit(
        rate - (p_discountPerc * rate) / 100
      );
      this.calculate();
    },
    changePurchaseRate() {
      let rate = parseFloat(this.items.purchase_rate || 0);
      let purchaseDiscountAmt = parseFloat(
        this.items.purchase_discount_amount || 0
      );

      if (purchaseDiscountAmt == 0 || isNaN(purchaseDiscountAmt)) {
        this.items.purchase_discount_percentage = 0;
        var purchaseDiscountPer = 0;
      } else {
        purchaseDiscountPer = this.parseDigit(
          (purchaseDiscountAmt /
            parseFloat(rate * parseFloat(this.items.quantity || 0))) *
            100
        );
        this.items.purchase_discount_percentage =
          this.parseDigit(purchaseDiscountPer);
      }
      this.items.actual_purchase_rate = this.parseDigit(
        rate - (purchaseDiscountPer * rate) / 100
      );
      this.calculate();
    },

    calculateAmount() {
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.bill_amount = 0;
      this.formData.due_amount = 0;
      this.formData.total = 0;
      this.formData.paid_amount =
        this.formData.paid_amount > 0
          ? this.parseDigit(this.formData.paid_amount)
          : 0;
      let vat = 0;
      this.formData.items.forEach((item) => {
        this.formData.total = this.parseDigit(
          parseFloat(this.formData.total) + parseFloat(item.total)
        );
      });
      this.calculateCustomAmount();
    },

    submitData() {
      if (this.formValidation()) {
        this.submitting = true;
        Services.storeOpeningStockData(this.formData)
          .then((res) => {
            if (
              res.status == 200 &&
              res.data.success_message == "Opening stock added successfully."
            ) {
              this.reset();
              this.setNotification(res.data.success_message);
            }
          })
          .catch((error) => {
            //validation error
            if (error.response.status == 422) {
              let err = Object.values(error.response.data.errors).flat();
              this.setNotification(err);
            } else {
              let err = error.response.data.error_message;
              this.setNotification(err);
            }
          })
          .finally(() => {
            this.submitting = false;
          });
      }
    },
    formValidation() {
      if (this.formData.items.length == 0) {
        this.setNotification("Please add items first.");
        return false;
      }
      return true;
    },
    printSlip(id) {
      //once the modal is opened popup print window
      window.print();
    },
    selectSupplier(id) {
      let details = this.dataSets.suppliers.find(function (data) {
        return data.id == id;
      });
      this.formData.supplier_id = details.id;
      this.formData.supplierName = details.name;
    },

    getData() {
      this.errorLoading = false;
      Services.getDataForOpeningStockCreation()
        .then((response) => {
          let value = response.data.data;
          this.dataSets.items = value.items;
          this.dataSets.suppliers = value.suppliers;
          this.dataSets.branches = value.branches;
          this.dataSets.purchase_setting = value.purchase_setting;
          this.dataSets.item_setting = value.item_setting;
          this.purchaseIdWithPrefix = "OS" + 0;
          this.formData.note = value.purchase_setting.description;
          this.formData.prefix = "OS";
          this.$store.commit("purchase/setItemToBePurchased", value.items);
          this.$store.commit("purchase/setItemGroups", value.item_groups);
          // checking if there is only one branch
          if (this.dataSets.branches.length == 1) {
            this.branchDisabled = true;
            this.formData.branch = value.branches[0]["id"];
            this.getDataByBranch(this.formData.branch);
          }
        })
        .catch((error) => {
          this.errorLoading = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.supplierLoading = false;
          this.$store.commit("purchase/setItemSearchLoading", false);
        });
    },
    getDataByBranch() {
      if (this.formData.branch) {
        this.clearItem();
        this.formData.payment_method = "";
        this.formData.paymentTitle = "";
        this.formData.bank = "";
        this.itemExpired = false;
        Services.getPurchaseItemsByBranch(this.formData.branch)
          .then((response) => {
            let value = response.data.data;
            this.$store.commit(
              "openingStock/setBranchId",
              this.formData.branch
            );
            this.dataSets.paymentMethods = value.paymentMethods;
            this.dataSets.banks = value.banks;
            this.purchaseId = value.purchaseId;
            this.formData.bill_no = value.purchaseId;
            this.purchaseIdWithPrefix = "OS" + value.purchaseId;
            this.dataSets.paymentMethods.forEach((payment) => {
              if (payment.title.toLowerCase() == "cash") {
                this.formData.payment_method = payment.id;
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.purchaseIdWithPrefix = 1;
      }
    },
    calculateTotal() {
      this.formData.vatable_amount = 0;
      this.formData.vat_amount = 0;
      this.formData.bill_amount = 0;
      this.formData.due_amount = 0;
      this.formData.total = 0;
      this.formData.paid_amount =
        this.formData.paid_amount > 0
          ? this.parseDigit(this.formData.paid_amount)
          : 0;
      this.formData.items.forEach((item) => {
        this.formData.total = this.parseDigit(
          this.formData.total + parseFloat(item.total)
        );
      });
      this.calculateCustomAmount();
    },
    calculateCustomAmount() {
      this.formData.purchase_amount = this.formData.total;
      this.formData.paid_amount = this.formData.total;
      this.formData.bill_amount = this.formData.total;
    },

    reset() {
      this.formData.chalan_no = null;
      this.formData.shipping_type = 0;
      this.formData.supplierName = "";
      this.formData.supplier_id = null;
      this.formData.driver = "";
      this.formData.vehicle = "";
      this.formData.contact = "";
      this.formData.paymentTitle = "";
      this.formData.bank = null;
      this.formData.bill_amount = null;
      this.formData.paid_amount = null;
      this.formData.total = null;
      this.formData.items = [];
      // single and multiple branch case
      if (this.dataSets.branches.length == 1) {
        this.branchDisabled = true;
        this.purchaseId++;
        this.formData.bill_no = this.purchaseId;
        this.purchaseIdWithPrefix =
          this.formData.prefix + this.formData.bill_no;
      } else {
        this.branchDisabled = false;
        this.formData.bill_no = ++this.purchaseId;
      }
      this.clearItem();
    },
    addFormDataToLocalStorage(value) {
      localStorage.setItem("openingStockFormData", JSON.stringify(value));
      this.$store.commit("openingStock/setBranchId", this.formData.branch);
      // this.calculateTotal();
    },
  },
  watch: {
    extraFields(value) {
      this.formData.extra_fields = value;
    },
    eventMessage(value) {
      if (value.indexOf("Supplier created successfully.") >= 0) {
        this.dataSets.suppliers = this.supplierDatas;
      }
      if (value.indexOf("Item created successfully.") >= 0) {
        this.dataSets.items = this.itemLists;
        this.$store.commit("openingStock/setItemToBePurchased", this.itemLists);
      }
    },
  },

  destroyed() {
    this.$store.dispatch("destroyEvent");
    localStorage.removeItem("openingStockFormData");
  },
};
</script>
<style scoped>
.form-control-custom h6 {
  font-weight: 600;
}

.right-select-drop {
  background: #1b52bf !important;
  color: #f4f7fa !important;
  border-radius: 5px !important;
  height: 1.4rem !important;
  font-size: 0.75rem;
}

.input-group-text {
  background: #1b52bf;
  color: #f4f7fa;
  z-index: 20;
}

.input-group-text {
  outline: none;
}

input,
select,
textarea {
  background: #f4f7fa;
}

input:focus,
select:focus,
textarea:focus {
  background: #ffffff;
}

.form-control:disabled {
  background: #9ac3f0 !important;
  color: black !important;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  top: 4px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>
